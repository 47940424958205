import api from '@/libs/axios'

export default {
  async createAccount(data){
    let response = await api.post('/admin/users/create-account', data, {isIgnoreNotify: true})
    return response.data.data.new_account || null
  },
  async activationLearn(payload) {
    let response = await api.post('/admin/activation-learn', payload, {isIgnoreNotify: true})
    return response
  },
}
