<template>
  <div>
    <page-breadcrumb title="Create account" class="mb-2" />

    <data-create-popup title="Create Group"
      :schema="create_schema" :default-data="{name: null, password: null, phone: null, language: null, activation_code: null}"
      :create-data-fn="createItem"
    />
  </div>
</template>
<script>
import service from '../service';
import {LANGUAGE_OPTIONS} from '../constant'
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Name', field: 'name', placeholder: "Enter Name", validate: { required: true } },
      { label: 'Phone', field: 'phone', placeholder: "Enter Phone - include country code, example 84834123456", input_type: "number", validate: { required: true } },
      { label: 'Password', field: 'password', placeholder: "Enter Password", validate: { required: true } },
      { label: 'Thị trường', field: 'language', input_type: 'select', options: LANGUAGE_OPTIONS },
      { label: 'Activation code', field: 'active_code'}
    ]
  }
];
export default {
  data(){
    return {
      create_schema,
    }
  },
  methods: {
    async createItem(data){
      let new_account = await service.createAccount({ ...data });
      if (new_account && data.active_code){
        let response = await service.activationLearn({
          email: new_account.email,
          phone: new_account.phone,
          active_code: data.active_code
        })
        if (response?.data?.data?.status) {
          this.$store.dispatch("pushSuccessNotify", {
            text: `Account ${new_account.email} is created with password: ${data.password}. Account is also activated successfully.`,
          });
        }
        else {
          this.$store.dispatch("pushErrorNotify", {
            text: `Activation code is invalid. Account ${new_account.email} is created with password: ${data.password}.`,
          });
        }
      }
      return new_account;
    }
  }
}
</script>